import { LifeCycles, registerApplication, setBootstrapMaxTime, setMountMaxTime } from 'single-spa'
import UrlPattern from 'url-pattern'
import {
  ApplicationConfiguration,
  diffTemplates,
  getApplicationConfigurations,
  getParsedTemplate,
  loadTemplates,
  Template
} from 'fe-common-app-layout-engine'

import { hasContext } from 'fe-common-app/lib/applicationSetup/context'
import { ContextType } from 'fe-common-app/lib/applicationSetup/types'
import { getErrorApplication } from './errorApplication'
import { load } from '../microfront'
import { setProgress } from './loader'
import _localMicroFronts from '../../../../microfronts_local.json'

import pageTemplates from './pages'
import logger from '../log'
import { MicroFrontendServerConfiguration } from '../microfront/configuration'
import { MicroFrontendScript } from '../microfront/loader'
import { getT } from '../ddWindow'

const localMicroFronts: string[] = _localMicroFronts
const SINGLE_SPA_TIMEOUT = 10000

const loadMicroFrontendScripts = async (
  configuration: MicroFrontendServerConfiguration,
  applications: ApplicationConfiguration[]
) => {
  /* Load scripts and attach reducers - one per unique micro frontend */
  let counter = 0
  const uniqueApplicationNames = [...new Set(applications.map((application) => application.name))]
  return Promise.all(
    uniqueApplicationNames.map(async (applicationName) => {
      const isLocal = localMicroFronts.includes(applicationName)
      const script = await load(applicationName, configuration[applicationName], isLocal)
      counter += 1
      setProgress(counter, uniqueApplicationNames.length)
      return script
    })
  )
}

const getMicroFrontendScript = (scripts: MicroFrontendScript[], name: string) => scripts.find((mf) => mf.name === name)

export const getMicroFrontendApplication = (
  componentName: string,
  domElementId: string,
  script?: MicroFrontendScript
) =>
  new Promise<LifeCycles>((resolve) => {
    const uiErrorMessage = 'Failed to load'
    try {
      if (script && script.script) {
        const application = script.script[componentName]
        resolve(application(domElementId))
      } else {
        resolve(getErrorApplication(componentName, domElementId, script?.error ?? uiErrorMessage))
      }
    } catch (e) {
      let errorMessage
      if (typeof e === 'string') {
        errorMessage = e
      } else if (e instanceof Error) {
        errorMessage = e.message
      }
      logger.error(`Failed to read micro-frontend application ${componentName} from script: ${errorMessage}`)
      resolve(getErrorApplication(componentName, domElementId, uiErrorMessage))
    }
  })

const getActiveRoute = () => `${window.location.pathname}${window.location.search}${window.location.hash}`

const getActiveRouteWithoutSearchAndHash = () => window.location.pathname

const isContextType = (x: unknown): x is ContextType => Object.values(ContextType).includes(x as ContextType)

const registerApplications = (scripts: MicroFrontendScript[], applications: ApplicationConfiguration[]) =>
  Promise.all(
    applications.map(async (app) => {
      const { name, component, routes, contexts, keepAlive, hashes } = app
      const { included: includedRoutes, excluded: excludedRoutes } = routes
      const { included: includedContexts, excluded: excludedContexts } = contexts
      const script = getMicroFrontendScript(scripts, name)
      const domElementId = `${name}-${component}`
      const mf = await getMicroFrontendApplication(component, domElementId, script)
      setBootstrapMaxTime(SINGLE_SPA_TIMEOUT, true)
      setMountMaxTime(SINGLE_SPA_TIMEOUT, true)
      registerApplication(
        domElementId,
        () => Promise.resolve(mf),
        () => {
          const includedRouteMatch = includedRoutes.some((route) => {
            const pattern = new UrlPattern(route)
            return pattern.match(getActiveRouteWithoutSearchAndHash()) !== null
          })
          const excludedRouteMatch = excludedRoutes.some((route) => {
            const pattern = new UrlPattern(route)
            return pattern.match(getActiveRouteWithoutSearchAndHash()) !== null
          })
          const includedContextMatch =
            includedContexts.length > 0
              ? includedContexts.some((context) => {
                  if (!isContextType(context)) throw Error(`Invalid context type ${context} in template`)
                  return !hasContext(context)
                })
              : true
          const excludedContextMatch = excludedContexts.some((context) => {
            if (!isContextType(context)) throw Error(`Invalid context type ${context} in template`)
            return hasContext(context)
          })
          const keepAliveRouteMatch = keepAlive.some((route) => {
            const pattern = new UrlPattern(route)
            return pattern.match(getActiveRouteWithoutSearchAndHash()) !== null
          })
          const hashMatch = hashes.length > 0 ? hashes.some((hash) => getActiveRoute().includes(hash)) : true

          const activityResult =
            includedRouteMatch && !excludedRouteMatch && includedContextMatch && !excludedContextMatch && hashMatch
          if (!activityResult) return keepAliveRouteMatch
          return activityResult
        }
      )
    })
  )

const getTemplateForSlot = (templates: Template[], slot: string) => {
  const slotTemplates = templates.filter((template) => template.slot === slot)
  return slotTemplates.find((template) => {
    const { routes, excludedRoutes } = template
    const includedMatch = routes.some((route) => {
      const pattern = new UrlPattern(route)
      return pattern.match(getActiveRouteWithoutSearchAndHash()) !== null
    })
    const excludedMatch = excludedRoutes.some((route) => {
      const pattern = new UrlPattern(route)
      return pattern.match(getActiveRouteWithoutSearchAndHash()) !== null
    })
    return includedMatch && !excludedMatch
  })
}

type PageDocument = {
  pageName: string
  parsedTemplate: Document
}
type PreviousTemplates = {
  [key: string]: PageDocument | null
}

const previousTemplates: PreviousTemplates = {}

const contextVisibilityHiddenPredicate = (values: string[]) =>
  values.some((context) => {
    if (!isContextType(context)) throw Error(`Invalid context type ${context} in template`)
    return hasContext(context)
  })

const mountPage = (templates: Template[], pageName: string, slot: string) => {
  if (!pageName) {
    // if no page has been found for given slot, it needs to be cleared
    const content = document.querySelector(`#${slot}`)
    previousTemplates[slot] = null
    if (content) content.innerHTML = ''
    return
  }

  const t = getT()
  if (!t) throw Error('Missing i18n translate function')
  const template = templates.find((tmp) => tmp.name === pageName)
  if (!template) throw Error('Could not find template')
  const parsedTemplate = getParsedTemplate(template, getActiveRoute(), contextVisibilityHiddenPredicate, t)

  const previousTemplate = previousTemplates[slot]
  if (previousTemplate && previousTemplate.pageName === pageName) {
    const { toBeAdded, toBeRemoved } = diffTemplates(previousTemplate.parsedTemplate, parsedTemplate)
    if (toBeAdded.length > 0) {
      toBeAdded.forEach((id) => {
        document.querySelector(`#${id}`)?.removeAttribute('hidden')
      })
    }
    if (toBeRemoved.length > 0) {
      toBeRemoved.forEach((id) => {
        document.querySelector(`#${id}`)?.setAttribute('hidden', 'true')
      })
    }
  } else {
    const markup = new XMLSerializer().serializeToString(parsedTemplate)
    const content = document.querySelector(`#${slot}`)
    if (content) content.innerHTML = markup
  }

  previousTemplates[slot] = { pageName, parsedTemplate }
}

const setupPageLoadEvents = (templates: Template[]) => {
  window.addEventListener('single-spa:before-mount-routing-event', () => {
    new Set(templates.map((template) => template.slot)).forEach((slot) => {
      const pageForSlot = getTemplateForSlot(templates, slot) || { name: '' }
      mountPage(templates, pageForSlot.name, slot)
    })
  })
}

export const getConfigWithoutDisabledMfs = (
  configuration: MicroFrontendServerConfiguration
): MicroFrontendServerConfiguration =>
  Object.fromEntries(Object.entries(configuration).filter(([mfVersion]) => configuration[mfVersion] !== 'null'))

export const getAppsWithoutDisabledMFs = (
  applications: ApplicationConfiguration[],
  configWithoutDisabledMfs: MicroFrontendServerConfiguration
) => applications.filter((app) => Object.prototype.hasOwnProperty.call(configWithoutDisabledMfs, app.name))

export const initApplications = async (configuration: MicroFrontendServerConfiguration) => {
  const templates = loadTemplates([...pageTemplates])
  setupPageLoadEvents(templates)
  const configWithoutDisabledMfs = getConfigWithoutDisabledMfs(configuration)
  const applications = getAppsWithoutDisabledMFs(getApplicationConfigurations(templates), configWithoutDisabledMfs)
  const scripts = await loadMicroFrontendScripts(configWithoutDisabledMfs, applications)
  return registerApplications(scripts, applications)
}
