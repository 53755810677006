import { get } from 'fe-common-xhr'

export type WorkOrder = {
  id: string
  productId: string
}

export type WorkOrderVehicle = {
  workOrderId: string
  invoiceTime: string | null
  organizationId: string
}

export const getWorkorder = async (workOrderId: string) => {
  const { data } = await get<WorkOrder>(`/api/workorder/workorders/${workOrderId}`)
  return data
}

export const getWorkOrderCards = async (productId: string): Promise<WorkOrderVehicle[]> => {
  const { data } = await get<WorkOrderVehicle[]>(`/api/workorder/work-order-cards?productId=${productId}`)
  return data
}
