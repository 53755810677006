import { LifeCycles } from 'single-spa'
import { ReducersMapObject } from 'redux'
import { remoteRepository, localRepository } from './constants'
import logger from '../log'
import { getAttachReducers } from '../ddWindow'

type MicroFrontendApplication = (domElementId: string) => LifeCycles

type ScriptWithReducer = {
  reducer?: ReducersMapObject
}

export type ScriptWithCssFile = {
  hasCssFile?: boolean
}

export type Script = {
  [key: string]: MicroFrontendApplication
} & ScriptWithReducer &
  ScriptWithCssFile

/* eslint-disable */
export const loadScript = (url: string): Promise<Script> => {
  const systemJS = require('systemjs/dist/system-production')
  return systemJS.import(url)
}
/* eslint-enable */

/* eslint-disable */
const loadComponent = async (scope: string, module: string): Promise<Script> => {
  // @ts-ignore
  await __webpack_init_sharing__('default')
  // @ts-ignore
  const container = window[scope] // or get the container somewhere else
  // Initialize the container, it may provide shared modules
  // @ts-ignore
  await container.init(__webpack_share_scopes__.default)
  // @ts-ignore
  const factory = await window[scope].get(module)
  const Module = factory()
  return Module as Script
}
/* eslint-enable */

const injectStylesheet = (href: string) => {
  const link = document.createElement('link')
  link.href = href
  link.type = 'text/css'
  link.rel = 'stylesheet'
  document.getElementsByTagName('head')[0].appendChild(link)
}

const isModuleFederated = (script: Script, scope: string): boolean => !!script[scope]

export type MicroFrontendScript = {
  name?: string
  script?: Script
  error?: string
}

export const hasCssFile = (script: ScriptWithCssFile): boolean => !!script.hasCssFile

export const load = async (microFront: string, version: string, local = false): Promise<MicroFrontendScript> => {
  const baseUrl = local ? `${localRepository}/${microFront}/lib` : `${remoteRepository}/${microFront}/${version}`
  const styleUrl = `${baseUrl}/index.css`
  const scriptUrl = `${baseUrl}/index.js`
  try {
    const scope = microFront.replace(/-/g, '_')
    const script = await loadScript(scriptUrl)
    const loadedScript = isModuleFederated(script, scope) ? await loadComponent(scope, './mfModule') : script
    if (hasCssFile(loadedScript)) {
      injectStylesheet(styleUrl)
    }
    const { reducer } = loadedScript
    const attachReducers = getAttachReducers()
    if (reducer && attachReducers) {
      attachReducers(reducer)
    }
    return { name: microFront, script: loadedScript }
  } catch (error) {
    logger.error(error)
    return Promise.resolve({ error: 'Failed to load micro frontend script' })
  }
}

export default load
