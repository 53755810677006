import { get } from 'fe-common-xhr'

export interface Product {
  id: string
}

export const getProduct = async (vin: string) => {
  const { data } = await get<Product[]>(`/api/product/products?vin=${vin}`)
  return data
}
