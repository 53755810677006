import { start } from 'single-spa'

import { authenticate } from 'fe-common-app/lib/user'
import { defineCustomElements } from 'fe-common-ui-library-wc/loader'
import { setUpWithOrganization, setUpWithoutOrganization } from 'fe-common-app/lib/applicationSetup'

import { getConfiguration } from './microfront'
import { hideLoader, initApplications, showLoader } from './singlespa'
import { setupI18n } from './i18n'
import {
  engagementMessengerOnLogin,
  initEngagementMessenger,
  injectEngagementMessengerScript
} from './engagement-messenger'

import '../scss/index.scss'
import logger from './log'
import { getStore } from './ddWindow'
import { beforeRoutingEventHandler } from './routing/routeAccess'

const showInfoMessage = (message = '') => {
  const infoMessageNode = document.querySelector('#app-info')
  if (infoMessageNode) {
    infoMessageNode.innerHTML = `
      <div id="app-info-message-wrapper">
        <div id="app-info-message-header">Failed to load application:</div>
        <div id="app-info-message-error">${message}</div>
      <div>
    `
    infoMessageNode.classList.remove('hidden')
  }
}

const registerEvents = () => {
  window.addEventListener('single-spa:before-routing-event', beforeRoutingEventHandler)

  window.addEventListener('single-spa:routing-event', () => {
    const pages = document.querySelectorAll('.page')
    pages.forEach((page) => {
      const element = page
      element.scrollTop = 0
    })
  })

  window.addEventListener('error', (errorEvent) => {
    logger.error(errorEvent)
  })
}

const setupWebComponents = () => defineCustomElements()
const startApplication = async () => {
  try {
    showLoader()
    injectEngagementMessengerScript()
    registerEvents()
    const organizationId = await authenticate(getStore())

    await setupI18n()
    setupWebComponents()

    if (organizationId) {
      const organization = await setUpWithOrganization(organizationId)
      const organizationConfiguration = await getConfiguration(organization.id)
      await initApplications(organizationConfiguration)
    } else {
      setUpWithoutOrganization()
      const defaultConfiguration = await getConfiguration('fakeorg')
      await initApplications(defaultConfiguration)
    }

    initEngagementMessenger()
    engagementMessengerOnLogin()
    start()
    hideLoader()
  } catch (error: unknown) {
    logger.error('Application has failed to start', error)
    if (typeof error === 'object' && error && 'message' in error && typeof error.message === 'string') {
      showInfoMessage(error.message)
    }
  }
}

await startApplication()

export default startApplication
