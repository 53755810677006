import { Store } from 'redux'
import { TFunction } from 'fe-common-i18n'
import { RootLogger } from 'fe-common-logger-core'
import { AttachReducers } from 'fe-common-app/lib/store'
import { DDWindow, I18n } from './types/globals'
import { createStore, ApplicationState } from './store'

export const initialState: DDWindow = {
  cdnUrl: 'https://cdn.drp.prod.aws.scania.com'
}

const initDDWindow = () => {
  window.dd = { ...initialState }
}

const getDDWindow = (): DDWindow => {
  if (!window.dd) {
    initDDWindow()
  }
  if (!window.dd) throw Error('window.dd not initialized')
  return window.dd
}

export const setT = (t: TFunction) => {
  getDDWindow().t = t
}

export const getT = (): TFunction | undefined => getDDWindow().t

export const setI18nVersion = (version: string) => {
  getDDWindow().i18nVersion = version
}

export const getI18nVersion = (): string | undefined => getDDWindow().i18nVersion

export const setI18n = (i18n: I18n) => {
  getDDWindow().i18n = i18n
}

export const getI18n = (): I18n | undefined => getDDWindow().i18n

export const setRootLogger = (logger: RootLogger) => {
  getDDWindow().log = logger
}

export const getRootLogger = (): RootLogger | undefined => getDDWindow().log

export const getAttachReducers = (): AttachReducers | undefined => getDDWindow().attachReducers

export const setStore = (store: Store<ApplicationState>) => {
  getDDWindow().store = store
}

export const getStore = (): Store<ApplicationState> => {
  const store = getDDWindow().store || createStore()
  if (!getDDWindow().store) {
    setStore(store)
  }
  return store
}
