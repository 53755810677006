const loadingMessage = 'Loading application'

const getAppInfoNode = () => document.querySelector('#app-info')

const multiplier = 100

export const setProgress = (current: number, total: number) => {
  const percentage = Math.round((current / total) * multiplier)
  const infoMessageNode = getAppInfoNode()
  if (infoMessageNode) infoMessageNode.textContent = `${loadingMessage} ${percentage}%`
}

export const showLoader = () => {
  const infoMessageNode = getAppInfoNode()
  if (infoMessageNode) {
    infoMessageNode.textContent = `${loadingMessage} 0%`
    infoMessageNode.classList.remove('hidden')
  }
}

export const hideLoader = () => {
  const infoMessageNode = getAppInfoNode()
  if (infoMessageNode) {
    infoMessageNode.textContent = ''
    infoMessageNode.classList.add('hidden')
  }
}
