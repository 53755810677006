import { navigateToUrl as spaNavigateToUrl } from 'single-spa'

export const ROUTES = {
  BASE: '/',
  SELF_SERVICE: '/selfservice',
  SERVICE_ADVISOR: '/serviceAdvisor',
  SERVICE_ADVISOR_PLANER: '/serviceAdvisor/planner',
  SERVICE_ADVISOR_PRODUCTS: '/serviceAdvisor/products',
  SERVICE_ADVISOR_PRODUCTS_PLANNER_TAB: '/serviceAdvisor/products',
  SERVICE_ADVISOR_PRODUCTS_WORK_ORDERS_TAB: '/serviceAdvisor/products-workorders',
  SERVICE_ADVISOR_CUSTOMER: '/serviceAdvisor/customer',
  ACTIVE_WORK_ORDER: '/activeWorkOrder',
  SEARCH_RESULT: '/searchResult',
  FLEET_CARE_ADMIN: '/fleetCareAdmin',
  FLEETS_OVERVIEW: '/fleetsOverview',
  BI_REPORTS: '/biReports',
  WS_ADMIN: '/wsAdmin',
  DISTRIBUTOR_ADMIN: '/distributorAdmin'
}

const getRoute = (): string => window.location.pathname

export const isExactRoute = (route: string): boolean => getRoute() === route

export const hasRoute = (route: string): boolean => window.location.pathname.includes(route)

export const getUrlSearch = (location: Location): URLSearchParams => new URLSearchParams(location.search)

export const getUrlSearchParams = (location: Location): string => {
  const params = getUrlSearch(location).toString()
  return params ? `?${params}` : ''
}

export const validUUIDRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const navigateToUrl = (url: string) => {
  const { location } = window || global
  const searchParams = getUrlSearchParams(location)
  spaNavigateToUrl(url + searchParams)
}

export const redirectToSelfService = (): void => {
  const url = `${window.location.origin}${ROUTES.SELF_SERVICE}/`
  window.location.assign(url)
}

export const matchUrl = (url: string, urlMatchPattern: RegExp) => {
  const urlObj = new URL(url)
  return urlMatchPattern.exec(urlObj.pathname)
}
