import productHtmlTemplate from './center/product.html'
import startTemplate from './center/start.html'
import biReport from './center/biReport.html'
import workshopAdmin from './center/workshopAdmin.html'
import distributorAdmin from './center/distributorAdmin.html'
import fleetCareAdmin from './center/fleetCareAdmin.html'
import fleetsOverview from './center/fleetsOverview.html'
import headerTemplate from './header/header.html'
import leftPanelTemplate from './leftPanel/leftPanel.html'
import productTabsTemplate from './center/productTabs.html'
import customerVehicles from './center/customerVehicles.html'
import fleetVehicles from './center/fleetVehicles.html'
import workOrderPreview from './center/workOrderPreview.html'

import headless from './headless/headless.html'

export default [
  productHtmlTemplate,
  startTemplate,
  biReport,
  workshopAdmin,
  distributorAdmin,
  fleetCareAdmin,
  fleetsOverview,
  headerTemplate,
  leftPanelTemplate,
  productTabsTemplate,
  customerVehicles,
  fleetVehicles,
  headless,
  workOrderPreview
]
