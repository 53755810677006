import rules from './rules'
import logger from '../log'
import { Detail, SingleSpaCustomEvent } from './types'

export const ensureRouteAccess = async (
  singleSpaCustomEvent?: SingleSpaCustomEvent,
  injectedRules?: ((detail: Detail) => Promise<boolean>)[]
): Promise<boolean> => {
  const { detail } = singleSpaCustomEvent || {}
  const rulesToProcess = injectedRules || rules

  if (detail) {
    // eslint-disable-next-line no-restricted-syntax
    for (const rule of rulesToProcess) {
      let hasMatch = false
      try {
        // eslint-disable-next-line no-await-in-loop
        hasMatch = await rule(detail)
      } catch (e) {
        logger.error(`there was a trouble resolving route guard rule ${rule.name}`, e)
      }
      if (hasMatch) break
    }
  }

  return false
}

/**
 * Handler uses single-spa event.detail.cancelNavigation.
 * When called with promise allows us to freeze navigation till promise is resolved.
 * Navigation will be resumed when function returns false or other navigation action is triggered
 * @param event
 */
export const beforeRoutingEventHandler = (event: unknown) => {
  logger.debug('before-routing-event', event)
  const singleSpaCustomEvent = event as SingleSpaCustomEvent
  const { detail } = singleSpaCustomEvent
  if (detail?.cancelNavigation) {
    detail.cancelNavigation(ensureRouteAccess(singleSpaCustomEvent))
  }
}
