import { Store } from 'redux'
import { initStore } from 'fe-common-app/lib/store'
import { reducer as appReducer } from 'fe-common-app/lib/app'
import { reducer as userReducer } from 'fe-common-app/lib/user'
import { CommonState } from 'fe-common-app/lib'
import { reducer as appProductReducer, AppProductState } from 'fe-common-app-state-product'

export type ApplicationState = CommonState & AppProductState

export const createStore = (): Store<ApplicationState> =>
  initStore<ApplicationState>({
    user: userReducer,
    app: appReducer,
    appProduct: appProductReducer
  })
