import { selectors as userSelectors } from 'fe-common-app/lib/user'
import { getStore } from '../ddWindow'
import { ROUTES } from './helpers'

export enum Roles {
  FLEET_MANAGER = 'fleet-manager',
  FLEET_CARE_ADMIN = 'fleet-care-admin',
  SERVICE_ADVISOR = 'service-advisor',
  BI_REPORT = 'bi-report',
  BI_PILOT = 'bi-pilot',
  BI_PILOT_2 = 'bi-pilot-2',
  ASSISTANCE_OPERATOR = 'assistance-operator'
}

export const AUTHORIZED_ROLES = [
  Roles.FLEET_CARE_ADMIN,
  Roles.BI_REPORT,
  Roles.BI_PILOT,
  Roles.BI_PILOT_2,
  Roles.ASSISTANCE_OPERATOR
]

const getRoles = (): string[] => {
  const { tokenParsed } = window.keycloak
  return tokenParsed.resource_access && tokenParsed.resource_access['dd-clients']
    ? tokenParsed.resource_access['dd-clients'].roles
    : []
}

export const hasRole = (role: string): boolean => getRoles().includes(role)

export const getActiveOrganization = (): string => {
  const state = getStore().getState()
  return userSelectors.activeOrganization(state.user)
}

export const hasActiveOrganization = (): boolean => !!getActiveOrganization()
const hasAnyRole = (): boolean => getRoles().length > 0
const hasAnyAuthorizedRoleInToken = (authorizedRoles: Roles[]): boolean => authorizedRoles.some(hasRole)
const isFleetCareAdmin = (): boolean => hasAnyAuthorizedRoleInToken([Roles.FLEET_CARE_ADMIN])

const hasOrganizationAndAnyAuthorizedRole = (): boolean =>
  hasActiveOrganization() || hasAnyAuthorizedRoleInToken(AUTHORIZED_ROLES)

const getTargetPage = (): string => `/${window.location.pathname.split('/')[1]}`

export const hasValidSetup = (): boolean => {
  switch (getTargetPage()) {
    case ROUTES.BASE:
      return hasOrganizationAndAnyAuthorizedRole()
    case ROUTES.BI_REPORTS:
      return true
    case ROUTES.FLEET_CARE_ADMIN:
      return isFleetCareAdmin()
    default:
      return hasOrganizationAndAnyAuthorizedRole()
  }
}

export const isWithoutRolesAndNotOnSelfService = (): boolean => !hasAnyRole() && getTargetPage() !== ROUTES.SELF_SERVICE
