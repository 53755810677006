import { LifeCycles } from 'single-spa'

export const getErrorApplication = (microfront: string, domElementID: string, errorMessage: string): LifeCycles => {
  const bootstrap = async (): Promise<void> => Promise.resolve()

  const mount = async (): Promise<void> => {
    const domElement = document.getElementById(domElementID)
    if (domElement) {
      domElement.classList.remove('unmounted')
      domElement.innerHTML = `An error occurred when loading ${microfront}: ${errorMessage}`
    }
    return Promise.resolve()
  }

  const unmount = async (): Promise<void> => {
    const domElement = document.getElementById(domElementID)
    if (domElement) {
      domElement.classList.add('unmounted')
      domElement.innerHTML = ''
    }
    return Promise.resolve()
  }

  return {
    bootstrap: [bootstrap],
    mount: [mount],
    unmount: [unmount]
  }
}

export default getErrorApplication
