import { updateToken } from 'fe-common-xhr'

const isProd = () => window.location.hostname === 'dealer.scania.com'
const isDev = () => window.location.hostname === 'dev.drp.devtest.aws.scania.com'

export const injectEngagementMessengerScript = () => {
  if (isProd() || isDev()) {
    const srcUrl = isProd()
      ? 'https://scanianow.scania.com/scripts/sn_csm_ec.js?v=3.1'
      : 'https://scanianowdev.scania.com/scripts/sn_csm_ec.js?v=3.1'
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', srcUrl)
    document.head.appendChild(script)
  }
}

const getModuleId = () =>
  isProd()
    ? 'https://scanianow.scania.com/#d8db8234dbfaf09444abb1b239961915'
    : 'https://scanianowdev.scania.com/#d8db8234dbfaf09444abb1b239961915'

export const initEngagementMessenger = () => {
  if (window.SN_CSM_EC) {
    window.SN_CSM_EC.init({
      moduleID: getModuleId(),
      loadFeature: window.SN_CSM_EC.loadEMFeature(),
      tokenCallBack: async () => {
        try {
          await updateToken()
          const { idToken } = window.keycloak
          return Promise.resolve(idToken) // eslint-disable-line
        } catch (error) {
          throw new Error('Failed to update token for Engagement messenger')
        }
      }
    })
  }
}

export const engagementMessengerOnLogin = () => {
  if (window.SN_CSM_EC) {
    window.SN_CSM_EC.onLogin()
  }
}
