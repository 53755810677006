import { getCDNUrl, initI18n, LanguageCode } from 'fe-common-i18n'
import { getFromCdn } from 'fe-common-xhr'

import logger from '../log'
import { setI18n, setI18nVersion, setT } from '../ddWindow'

export const loadActiveVersion = async (): Promise<void> => {
  try {
    const cdnUrl = getCDNUrl('serviceadvisor', false)
    const response = await getFromCdn<{ version: string }>(`${cdnUrl}version.json`)
    const { version } = response.data
    const supportedLanguages = await getFromCdn<LanguageCode[]>(`${cdnUrl}${version}/supportedLanguages.json`)
    setI18nVersion(version)
    setI18n({ version, supportedLanguages: supportedLanguages.data })
  } catch (error) {
    throw new Error('Failed to load i18n version number')
  }
}

export const setupI18n = async () => {
  let t
  try {
    await loadActiveVersion()
    const i18n = await initI18n('app', 'serviceadvisor')
    t = i18n.t
  } catch (error) {
    logger.error('Failed to setup i18n', error) // eslint-disable-line no-console
    t = (key: string, fallback: string | undefined) => fallback
  }
  setT(t)
  return t
}

export default initI18n
