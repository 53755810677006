import { get } from 'fe-common-xhr'

import { configService } from './constants'

export type MicroFrontendServerConfiguration = Record<string, string>

export type MicroFrontendQueryParams = MicroFrontendServerConfiguration

/*
 * Fetch configuration from server
 */
const fetchServerConfiguration = async (organizationId: string): Promise<MicroFrontendServerConfiguration> => {
  const serviceUrl = organizationId ? `${configService}/${organizationId}` : configService
  return get<MicroFrontendServerConfiguration>(serviceUrl)
    .catch(() => {
      throw new Error(`Failed to parse micro frontend configuration`)
    })
    .then((response) => response.data)
}

/*
 * Get versions from query params
 * url?fe-workshop-history=1.3.4&fe-campaign=3.2.1
 */
const getParams = (location: Location): MicroFrontendQueryParams =>
  location.search
    .substring(1)
    .split('&')
    .reduce<MicroFrontendQueryParams>((result, param) => {
      const [mf, version] = param.split('=')
      if (mf && version) {
        return { ...result, [mf]: version }
      }
      return result
    }, {})

/*
 * Add versions from query params to config
 */
export const addQueryParamConfig = (
  configuration: MicroFrontendServerConfiguration,
  queryParams: MicroFrontendQueryParams
): MicroFrontendServerConfiguration =>
  Object.entries(configuration).reduce<MicroFrontendServerConfiguration>(
    (result, [mf, defaultVersion]) => ({
      ...result,
      [mf]: queryParams[mf] ?? defaultVersion
    }),
    {}
  )

/*
 * Get the micro frontend configuration for organization
 */
export const getConfiguration = async (organizationId: string): Promise<MicroFrontendServerConfiguration> => {
  const serverConfig = await fetchServerConfiguration(organizationId)
  const queryParams = getParams(window.location)
  return addQueryParamConfig(serverConfig, queryParams)
}
